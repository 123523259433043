<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Descripción -->
    <div class="d-flex align-center mb-3">
      <v-icon color="blue darken-4" size="55">library_books</v-icon>
      <span class="ms-4 descripcion">
        Herramienta diseñada para generar reportes en formato CSV 
        <br> de las solicitudes de medicamentos programadas y/o a domicilio en un periodo de hasta un mes.
      </span>
    </div>
    <v-divider color="#9E9E9" class="mb-5"></v-divider>

    <!-- Filtros para buscar un reporte -->
    <section>
      <v-container class="container">
        <v-row>
          <!-- Campo para filtrar por fecha inicial -->
          <v-col cols="12" sm="4" md="4" class="pa-0">
            <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
              :close-on-content-click="false" :return-value.sync="fechaInicialCalendario" :nudge-top="25" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field ref="refFechaInicial" v-model="fechaInicialCalendario" label="Fecha inicial *" v-bind="attrs" v-on="on"
                  outlined dense readonly :rules="rules.agregarRules" class="pr-1 pl-1">
                </v-text-field>
              </template>
              <v-date-picker v-model="fechaInicialCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable @input="saveFechaInicial">
                <!-- <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cleanFechaInicial">Borrar</v-btn>
                <v-btn text color="primary" @click="saveFechaInicial">OK</v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Campo para filtrar por fecha final -->
          <v-col cols="12" sm="4" md="4" class="pa-0">
            <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
              :close-on-content-click="false" :return-value.sync="fechaFinalCalendario" :nudge-top="25" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field ref="refFechaFinal" v-model="fechaFinalCalendario" label="Fecha final *" v-bind="attrs" v-on="on" 
                  outlined dense readonly :error-messages="rangoFechas" :rules="rules.agregarRules" class="pr-1 pl-1">
                </v-text-field>
              </template>
              <v-date-picker v-model="fechaFinalCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable @input="saveFechaFinal">
                <!-- <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cleanFechaFinal">Borrar</v-btn>
                <v-btn text color="primary" @click="saveFechaFinal">OK</v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Filtro por estado -->
          <v-col cols="12" sm="4" md="4" class="pa-0">
            <v-autocomplete ref="filtroEstado" v-model="filtroEstado" label="Estado" outlined dense :items="tiposEstado" 
              :menu-props="{offsetY: true, maxHeight: 200}" @keydown="tabulador($event, 'filtroEstado', 'filtroEstado')"
              :disabled="inProgress === true" no-data-text="Sin resultados" class="pr-1 pl-1">
            </v-autocomplete>
          </v-col>
            
        </v-row>
      </v-container>
      

      <!-- Botón de generar reporte -->
      <v-sheet class="d-flex align-center justify-center" :color="disableReport ? 'grey lighten-4' : 'green lighten-5'" height="65px" width="100%" rounded>
        <v-btn v-if="inProgress === false" color="success" @click="listarReporte()" :disabled="disableReport">
          <v-icon>download</v-icon>
          generar reporte
        </v-btn>
        <div v-else class="d-flex justify-center">
          <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
          <span class="d-flex align-center font-weight-black primary--text ms-2">CARGANDO</span>
        </div>
      </v-sheet>
    </section>

    <!-- Alerta cuando no se encuentran resultados -->
    <v-alert class="mt-5" icon="error" color="error" transition="scale-transition" :value="alert" outlined>
      <h3>No se encontraron resultados.</h3>
      <p class="no-resultados">Los datos ingresados no coinciden con ningún registro.
      <br>Verifica que los filtros sean correctos o intenta una búsqueda diferente.</p>
    </v-alert>

  </div>
</template>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "@/router/role.js";
import Papa from 'papaparse'

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
function arrayToCSV(data) {
  return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "ReporteSolicitudesEntregasDomicilio",
  data() {
    return {
      filtro: {
        fechaInicial: '',
        fechaFinal: '',
      },
      filtroEstado: null,
      tiposEstado: [
        { text: 'Creado', value: 'CREATE' },
        { text: 'Abierto', value: 'OPEN' },
        { text: 'Validado', value: 'VALIDATED' },
        { text: 'Rechazado', value: 'REFUSED' },
        { text: 'Resuelto', value: 'RESOLVED' },
        { text: 'Cerrado', value: 'CLOSE' },
        { text: 'Novedad', value: 'NOVELTY' },
      ],
      menuFechaInicio: false,
      menuFechaFinal: false,
      singleSelect: true,
      mensajeError: '',
      alert: false,
      rangoFechas: '',
      inProgress: false,
      userRoles: {},
      roles: Role,
      fechaInicialCalendario: '',
      fechaFinalCalendario: '',
      rules: {
        agregarRules: [
          v => !!v || "Este campo es requerido.",
        ],
      },
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
    /** Función usada para deshabilitar o habilitar el botón para generar un reporte de acuerdo a las propiedades de los filtros. */
    disableReport() {
      return this.filtro.fechaInicial === '' || 
        this.filtro.fechaFinal === '' || 
        this.rangoFechas !== '' || 
        this.menuFechaInicio === true ||
        this.menuFechaFinal === true;
    },
  },
  watch: {  
    /**
     * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
     * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
     * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
     */
    filtro: {
      deep: true,
      handler() {
        this.alert = false;
      }
    },
  },
  methods: {
    /**
     * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
     * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
     * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
     */
    validacionesFechas() {
      const fechaUno = new Date(`${this.filtro.fechaInicial}T00:00:00-05:00`);
      const fechaDos = new Date(`${this.filtro.fechaFinal}T00:00:00-05:00`);

      if (fechaUno > fechaDos) {
        this.rangoFechas = 'La fecha final debe ser mayor a la fecha inicial';
        return;
      }

      let fechaMaximaPermitida = new Date(fechaUno);
      fechaMaximaPermitida.setMonth(fechaMaximaPermitida.getMonth() + 1);

      if (fechaUno.getDate() === 1) {
        fechaMaximaPermitida.setDate(0);
      }

      if (fechaDos > fechaMaximaPermitida) {
        this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes';
      } else {
        this.rangoFechas = '';
      }
    },
    /** Guarda la fecha inicial seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaInicial() {
      this.$refs.menuFechaInicio.save(this.fechaInicialCalendario);
      this.filtro.fechaInicial = this.fechaInicialCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha inicial seleccionada y restablece los campos relacionados. */
    cleanFechaInicial() {
      this.fechaInicialCalendario = '';
      this.$refs.menuFechaInicio.save('');
      this.filtro.fechaInicial = '';
    },
    /** Guarda la fecha final seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaFinal() {
      this.$refs.menuFechaFinal.save(this.fechaFinalCalendario);
      this.filtro.fechaFinal = this.fechaFinalCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha final seleccionada y restablece los campos relacionados. */
    cleanFechaFinal() {
      this.fechaFinalCalendario = '';
      this.$refs.menuFechaFinal.save('');
      this.filtro.fechaFinal = '';
    },
    /**
     * Solicita y procesa los datos de los paquetes entregados basándose en los filtros.
     * Este método obtiene un conjunto de datos relacionados con los paquetes entregados 
     * por la transportadora. La respuesta esperada es un blob que contiene datos en formato CSV. Estos datos son luego 
     * procesados y convertidos a un archivo .csv para su descarga.
     */
    listarReporte() {
      this.inProgress = true;
      let estado = this.filtroEstado === null ? '' : this.filtroEstado;
      this.$http
      .get(`msa-reports/api/solicitudEntrega/reporte`, {
        responseType: 'blob',
        params: {
          idEmpresa: this.enterprise.code,
          fechaInicial: this.filtro.fechaInicial,
          fechaFinal: this.filtro.fechaFinal,
          estado: `${estado}`,
        }
      }).then(response => {
        const filename = `reporte_solicitud_portal_de_medicamentos_${this.filtro.fechaInicial.replace(/-/g, '_')}_al_${this.filtro.fechaFinal.replace(/-/g, '_')}.csv`;
        Papa.parse(response.data, {
          complete: (results) => {
            const csvData = arrayToCSV(results.data);
            if(results.data.length > 2) {
              downloadCSV(csvData, filename);
              this.clear();
              this.$refs.refFechaInicial?.reset();
              this.$refs.refFechaFinal?.reset();
            } else {
              this.alert = true;
            }
          }
        });
        this.inProgress = false;
      }).catch(error => {
        console.log(error);
      })
    },
    /**
     * Limpia y reinicia el estado del formulario y sus validaciones.
     * Este método resetea las validaciones del formulario mediante el observer de VeeValidate.
     * Además, se encarga de limpiar las fechas seleccionadas, el tipo de transacción, el NIT de la transportadora y 
     * cualquier mensaje de error asociado a las fechas.
     */
    clear() {
      this.filtroEstado = null;
      this.cleanFechaInicial();
      this.cleanFechaFinal()
      this.rangoFechas = '';
      this.alert = false;
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this[model] === null) {
        this.$refs[ref].blur();
        this[model] = '';
      }
    },
  }
}
</script>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Estilos                                                     ###### -->
 <!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}
.descripcion {
  color: rgb(64, 62, 62); 
}
.no-resultados {
  font-size: 15px;
  margin: 0;
}
::v-deep .estiloFilas div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .estiloFilas div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
.estiloFilas tbody tr td {
  font-size: 14px !important;
}
::v-deep .estiloFilas .v-data-footer {
  width: 100% !important;
}
::v-deep .estiloFilas .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .estiloFilas .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.container {
  max-width: none !important;
  width: 100% !important;
}
</style>